import React, { useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import VisibilitySensor from "react-visibility-sensor";

const Lottie = loadable(() => import("./Lottie"));

function LottieWrapper({
  visible,
  useVisibilitySensor,
  partialVisibility,
  ...otherProps
}) {
  const [currentHeight, setCurrentHeight] = useState(0);

  if (useVisibilitySensor) {
    return (
      <div style={{ height: currentHeight }}>
        <VisibilitySensor partialVisibility={partialVisibility}>
          {({ isVisible }) => {
            return (
              <div className="min-h-3">
                {isVisible ? (
                  <Lottie {...otherProps} setCurrentHeight={setCurrentHeight} />
                ) : null}
              </div>
            );
          }}
        </VisibilitySensor>
      </div>
    );
  }

  return (
    <div style={{ height: currentHeight }}>
      {visible ? (
        <Lottie {...otherProps} setCurrentHeight={setCurrentHeight} />
      ) : null}
    </div>
  );
}

LottieWrapper.propTypes = {
  visible: PropTypes.bool,
  useVisibilitySensor: PropTypes.bool,
  partialVisibility: PropTypes.bool,
};

LottieWrapper.defaultProps = {
  visible: false,
  useVisibilitySensor: false,
  partialVisibility: false,
};

export default LottieWrapper;
