import React from "react";
import { FormattedMessage } from "react-intl";

import VisibilitySensor from "react-visibility-sensor";

import ExternalLink from "./ExternalLink";
import LottieWrapper from "./LottieVisibilityWrapper";

import guyWithTable from "../animations/guy-with-table.json";
import cat from "../animations/cat.json";
import FormattedHTMLMessage from "./FormattedHTMLMessage";

const ConversionRow = () => (
  <VisibilitySensor partialVisibility>
    {({ isVisible }) => {
      return (
        <div className="flex mt-32 bg-cream p-8 md:p-12">
          <div className="container mx-auto flex flex-row flex-wrap-reverse items-center relative">
            <div className="flex flex-col w-full md:w-1/2">
              <LottieWrapper animationData={guyWithTable} visible={isVisible} />
            </div>
            <div className="flex flex-col justify-between w-full md:w-1/2 md:p-4">
              <div className="lg:mb-12">
                <h2 className="w-full text-center md:text-left items-center text-3xl xl:text-5xl font-bold">
                  <FormattedHTMLMessage id="components.conversion-row.headline" />
                </h2>
                <div className="flex flex-row w-full md:w-auto">
                  <ExternalLink
                    to="https://meet.appointer.com"
                    className="button shadow-none button-xl mt-12 my-4 ga-start-now-button"
                  >
                    <FormattedMessage id="common.get-started" />
                  </ExternalLink>
                </div>
              </div>
            </div>
            <div className="absolute w-full left-0 bottom-0 pb-4 xl:pb-5">
              <div className="divider-flat hidden lg:block" />
            </div>
            <LottieWrapper
              className="absolute bottom-0 right-0 hidden lg:block mr-32 -mt-24"
              animationData={cat}
              width={167.3}
              visible={isVisible}
            />
          </div>
        </div>
      );
    }}
  </VisibilitySensor>
);

export default ConversionRow;
